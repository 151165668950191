import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useOptionsContainer from "./OptionsContainer.hook";
import { OptionType } from "../../../lib/apollo/graphql/generated";
import { OPTION_TYPES } from "../../../utilities/constants";
import OptionCategoryCard from "../components/OptionCategoryCard";

function OptionsContainer() {
  const {
    models: { loading, data },
  } = useOptionsContainer();

  if (loading) return <div />;

  return (
    <Wrapper>
      <OptionCategoryCard
        type={OPTION_TYPES.readinessOptions as OptionType}
        data={data?.readinessOptions!}
      />
      <OptionCategoryCard
        type={OPTION_TYPES.productOptions as OptionType}
        data={data?.productOptions!}
      />
      <OptionCategoryCard
        type={OPTION_TYPES.purposeOptions as OptionType}
        data={data?.purposeOptions!}
      />
      <OptionCategoryCard
        type={OPTION_TYPES.bmOptions as OptionType}
        data={data?.bmOptions!}
      />
      <OptionCategoryCard
        type={OPTION_TYPES.funnelOptions as OptionType}
        data={data?.funnelOptions!}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "flex-start",
  gap: theme.spacing.middle,
  flexWrap: "wrap",
  width: "100%",
  padding: theme.spacing.default,
}));

export default OptionsContainer;
