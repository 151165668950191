import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useContactListHeader from "./ContactListHeader.hook";

interface ContactListHeaderProps {
  searchedQuery: string;
  onSearch: (query: string) => void;
  onRefetchClick: () => void;
}

function ContactListHeader({
  searchedQuery,
  onSearch,
  onRefetchClick,
}: ContactListHeaderProps) {
  const {
    models: { query },
    operations: { onChange },
  } = useContactListHeader(searchedQuery);

  return (
    <Header>
      <Button onClick={onRefetchClick}>Refresh</Button>
      <SearchWrapper>
        <SearchInput
          placeholder="단체명, 이메일, 담당자이름"
          value={query}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            const { key } = e;
            if (key === "Enter") {
              onSearch(query);
            }
          }}
          onChange={onChange}
        />
        <Button onClick={() => onSearch(query)}>Search</Button>
      </SearchWrapper>
    </Header>
  );
}

const Header = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "stretch",
  justifyContent: "space-between",
  marginBottom: theme.spacing.default,
}));

const Button = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  backgroundColor: theme.color.black,
  color: theme.color.white,
  padding: `${theme.spacing.xxSmall2}px ${theme.spacing.small}px`,
}));

const SearchWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "stretch",
  gap: theme.spacing.small,
  ...theme.font.body3,
}));

const SearchInput = styled.input<any>(({ theme }: ThemedComponentProps) => ({
  minWidth: 150,
  border: `1px solid ${theme.color.black4}`,
  padding: theme.spacing.small,
}));

export default ContactListHeader;
