import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import usePrivacyPolicyContainer from "./PrivacyPolicyContainer.hook";
import Editor from "../../common/components/Editor";
import PrivacyPolicyHeader from "../components/PrivacyPolicyHeader";

function PrivacyPolicyContainer() {
  const {
    models: {
      versionLoading,
      versionData,
      loading,
      data,
      currentPrivacyPolicy,
    },
    operations: { onEditorChange, onVersionChange, onSubmit },
  } = usePrivacyPolicyContainer();

  if (versionLoading) return <div />;

  return (
    <>
      <PrivacyPolicyHeader
        currentId={currentPrivacyPolicy.id}
        versionData={versionData}
        onVersionChange={onVersionChange}
      />
      {!loading && (
        <Wrapper>
          <Editor
            value={currentPrivacyPolicy.policy}
            onChange={onEditorChange}
          />
          <SubmitWrapper>
            <Submit onClick={onSubmit}>등록</Submit>
          </SubmitWrapper>
        </Wrapper>
      )}
    </>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.middle,
}));

const SubmitWrapper = styled.div<any>(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

const Submit = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  backgroundColor: theme.color.yellow1,
  padding: theme.spacing.normal,
  margin: theme.spacing.large,
}));

export default PrivacyPolicyContainer;
