import imageCompression from "browser-image-compression";
import React, { useEffect, useRef, useState } from "react";
import { COMPRESSION_OPTION } from "../../../utilities/configs";

import {
  GetAllOptionsDocument,
  OptionType,
  useDeleteOptionAsAdminMutation,
  useUpdateOptionAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

const defaultState: {
  title: string;
  titleEng: string;
  sort: number;
  active: boolean;
  icon:
    | { __typename?: "Media" | undefined; id: string; uri: string }
    | null
    | undefined;
  iconState: any;
} = {
  title: "",
  titleEng: "",
  sort: 0,
  active: false,
  icon: null,
  iconState: null,
};

function useOptionCard(
  type: OptionType,
  option?: {
    __typename?: "Option" | undefined;
    id: string;
    type: OptionType;
    title: string;
    titleEng: string;
    active: boolean;
    sort: number;
    icon?:
      | { __typename?: "Media" | undefined; id: string; uri: string }
      | null
      | undefined;
  } | null
) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [editMode, setEditMode] = useState(false);
  const [state, setState] = useState(defaultState);

  const [updateOptionAsAdmin] = useUpdateOptionAsAdminMutation({
    onCompleted: () => {
      fileInputRef.current!.value = "";
      setState(defaultState);
      if (option?.id) {
        setEditMode(false);
      }
    },
    refetchQueries: () => [
      {
        query: GetAllOptionsDocument,
      },
    ],
  });

  const [deleteOptionAsAdmin] = useDeleteOptionAsAdminMutation({
    refetchQueries: () => [
      {
        query: GetAllOptionsDocument,
      },
    ],
  });

  useEffect(() => {
    if (!option?.id) {
      setEditMode(true);
    }
  }, [option?.id]);

  async function onEditClick() {
    if (editMode) {
      const { title, titleEng, sort, active, iconState } = state;

      const optionInput: {
        title: string;
        titleEng: string;
        sort: number;
        active: boolean;
        id: string | null;
        type: OptionType;
        iconInput: {
          file: any;
        } | null;
      } = {
        title,
        titleEng,
        sort: Number(sort),
        active,
        id: option?.id || null,
        type,
        iconInput: null,
      };

      if (iconState) {
        const compressedFile = await imageCompression(
          iconState,
          COMPRESSION_OPTION
        );

        optionInput.iconInput = {
          file: compressedFile,
        };
      }

      await updateOptionAsAdmin({
        variables: {
          optionInput,
        },
      });
    } else {
      setEditMode(true);

      setState({
        title: option?.title!,
        titleEng: option?.titleEng || "",
        sort: option?.sort!,
        active: option?.active!,
        icon: option?.icon,
        iconState: null,
      });
    }
  }

  function onCancelClick() {
    setEditMode(false);
  }

  function onDeleteClick(optionId: string) {
    const confirm = window.confirm("정말로 삭제하시겠습니까?");

    if (confirm) {
      deleteOptionAsAdmin({
        variables: {
          optionId,
          type,
        },
      });
    }
  }

  function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;

    if (files) {
      const file = files[0];
      setState((prev) => ({
        ...prev,
        iconState: file,
      }));
    }
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
    const { value, checked } = e.target;

    if (key === "active") {
      setState((prev) => ({
        ...prev,
        active: checked,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  }

  return {
    refs: {
      fileInputRef,
    },
    models: {
      editMode,
      state,
    },
    operations: {
      onEditClick,
      onCancelClick,
      onChange,
      onFileChange,
      onDeleteClick,
    },
  };
}

export default useOptionCard;
