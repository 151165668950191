import { useEffect, useState } from "react";
import {
  GetPrivacyPolicyVersionsDocument,
  useGetPrivacyPolicyLazyQuery,
  useGetPrivacyPolicyVersionsQuery,
  useUpdatePrivacyPolicyAsAdminMutation,
} from "../../../lib/apollo/graphql/generated";

function usePrivacyPolicyContainer() {
  const [currentPrivacyPolicy, setCurrentPrivacyPolicy] = useState({
    id: "",
    policy: "",
  });

  const [getPrivacyPolicy, { loading, data }] = useGetPrivacyPolicyLazyQuery({
    onCompleted: ({ getPrivacyPolicy }) => {
      if (getPrivacyPolicy?.id) {
        setCurrentPrivacyPolicy({
          id: getPrivacyPolicy?.id,
          policy: getPrivacyPolicy?.policy,
        });
      }
    },
  });

  const { loading: versionLoading, data: versionData } =
    useGetPrivacyPolicyVersionsQuery();

  const [updatePrivacyPolicyAsAdmin] = useUpdatePrivacyPolicyAsAdminMutation({
    onCompleted: ({ updatePrivacyPolicyAsAdmin }) => {
      setCurrentPrivacyPolicy({
        id: updatePrivacyPolicyAsAdmin.id,
        policy: updatePrivacyPolicyAsAdmin?.policy,
      });
    },
    refetchQueries: !currentPrivacyPolicy?.id
      ? () => [
          {
            query: GetPrivacyPolicyVersionsDocument,
          },
        ]
      : [],
  });

  useEffect(() => {
    if (!versionLoading && versionData?.getPrivacyPolicyVersions?.[0]) {
      getPrivacyPolicy({
        variables: {
          policyId: versionData?.getPrivacyPolicyVersions[0]?.id,
        },
      });
    }
  }, [versionLoading, versionData]);

  function onEditorChange(value: string) {
    setCurrentPrivacyPolicy((prev) => ({
      ...prev,
      policy: value,
    }));
  }

  function onVersionChange(
    e?: React.ChangeEvent<HTMLSelectElement>,
    isNew?: boolean
  ) {
    if (!isNew && e) {
      const { value } = e.target;
      getPrivacyPolicy({
        variables: {
          policyId: value,
        },
      });
    } else {
      setCurrentPrivacyPolicy({
        id: "",
        policy: "",
      });
    }
  }

  function onSubmit() {
    updatePrivacyPolicyAsAdmin({
      variables: {
        privacyPolicyInput: {
          id: currentPrivacyPolicy?.id || null,
          policy: currentPrivacyPolicy.policy,
        },
      },
    });
  }

  return {
    models: {
      loading,
      versionLoading,
      versionData: versionData?.getPrivacyPolicyVersions,
      data: data?.getPrivacyPolicy,
      currentPrivacyPolicy,
    },
    operations: {
      onEditorChange,
      onVersionChange,
      onSubmit,
    },
  };
}

export default usePrivacyPolicyContainer;
