import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import moment from "moment";
import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useContactListRow from "./ContactListRow.hook";
import { OPTION_TYPE_NAMES } from "../../../../utilities/constants";

interface ContactListRowProps {
  contact: any;
}

function ContactListRow({ contact }: ContactListRowProps) {
  const {
    models: { showDetail },
    operations: { toggleShowDetail, onUpdateContactedAtClick },
  } = useContactListRow();

  const {
    id,
    team,
    managerName,
    managerPosition,
    phone,
    email,
    budget,
    media,
    contactedAt,
    productOptions,
    readinessOptions,
    bmOptions,
    purposeOptions,
    funnelOptions,
    createdAt,
  } = contact;

  return (
    <>
      <tr
        style={{
          backgroundColor: showDetail ? "rgba(255,123,0,0.4)" : "white",
        }}
      >
        <Td>{id}</Td>
        <Td>{team}</Td>
        <Td>
          {managerName}/{managerPosition}
        </Td>
        <Td>
          <a href={`tel:${phone}`}>{phone}</a>
        </Td>
        <Td>
          <a href={`mailto:${email}`}>{email}</a>
        </Td>
        <Td>{budget}</Td>
        <Td>{productOptions[0].title}</Td>
        <Td>{media ? "O" : "X"}</Td>
        <Td>{moment(createdAt).format("YYYY-MM-DD HH:mm:ss")}</Td>
        <Td>
          {contactedAt ? (
            moment(contactedAt).format("YYYY-MM-DD HH:mm:ss")
          ) : (
            <button onClick={() => onUpdateContactedAtClick(id)}>연락함</button>
          )}
        </Td>
        <Td>
          <ViewMoreButton onClick={toggleShowDetail}>더보기</ViewMoreButton>
        </Td>
      </tr>
      {showDetail && (
        <tr>
          <Td colSpan={10}>
            <ViewMoreWrapper>
              <CategoryOptionsWrapper>
                <OptionsWrapper>
                  <Option>
                    <strong>{OPTION_TYPE_NAMES.PRODUCT}</strong>
                  </Option>
                  {productOptions.map((option: any) => (
                    <Option key={option.id}>{option.title}</Option>
                  ))}
                </OptionsWrapper>
                <OptionsWrapper>
                  {" "}
                  <Option>
                    <strong>{OPTION_TYPE_NAMES.READINESS}</strong>
                  </Option>
                  {readinessOptions.map((option: any) => (
                    <Option key={option.id}>{option.title}</Option>
                  ))}
                </OptionsWrapper>
                <OptionsWrapper>
                  <Option>
                    <strong>{OPTION_TYPE_NAMES.PURPOSE}</strong>
                  </Option>
                  {purposeOptions.map((option: any) => (
                    <Option key={option.id}>{option.title}</Option>
                  ))}
                </OptionsWrapper>
                <OptionsWrapper>
                  <Option>
                    <strong>{OPTION_TYPE_NAMES.BM}</strong>
                  </Option>
                  {bmOptions.map((option: any) => (
                    <Option key={option.id}>{option.title}</Option>
                  ))}
                </OptionsWrapper>
                <OptionsWrapper>
                  <Option>
                    <strong>{OPTION_TYPE_NAMES.FUNNEL}</strong>
                  </Option>
                  {funnelOptions.map((option: any) => (
                    <Option key={option.id}>{option.title}</Option>
                  ))}
                </OptionsWrapper>
              </CategoryOptionsWrapper>

              {contact.media && (
                <FileLink href={contact.media.uri} target="_blank">
                  파일보기({contact.media.type})
                </FileLink>
              )}
            </ViewMoreWrapper>
          </Td>
        </tr>
      )}
    </>
  );
}

const Td = styled.td<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.small}px ${theme.spacing.small}px`,
  border: `1px solid ${theme.color.black8}`,
}));

const ViewMoreButton = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.xxSmall2}px ${theme.spacing.small}px`,
  backgroundColor: theme.color.black7,
}));

const ViewMoreWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.normal,
}));

const CategoryOptionsWrapper = styled.div<any>(
  ({ theme }: ThemedComponentProps) => ({
    display: "flex",
    alignItems: "flex-start",
    gap: theme.spacing.xxSmall2,
    marginBottom: theme.spacing.small,
  })
);

const OptionsWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  border: `1px solid ${theme.color.black7}`,
  ...theme.font.body3,
  borderBottom: "none",
}));

const Option = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  borderBottom: `1px solid ${theme.color.black7}`,
  padding: `${theme.spacing.xxSmall2}px ${theme.spacing.small}px`,
  width: "100%",
}));

const FileLink = styled.a<any>(() => ({
  color: "red",
}));

export default ContactListRow;
