import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useOptionCard from "./OptionCard.hook";
import { Option, OptionType } from "../../../lib/apollo/graphql/generated";

interface OptionCardProps {
  type: OptionType;
  option?: {
    __typename?: "Option" | undefined;
    id: string;
    type: OptionType;
    title: string;
    titleEng: string;
    active: boolean;
    sort: number;
    icon?:
      | { __typename?: "Media" | undefined; id: string; uri: string }
      | null
      | undefined;
  } | null;
}

function OptionCard({ type, option }: OptionCardProps) {
  const {
    refs: { fileInputRef },
    models: { editMode, state },
    operations: {
      onEditClick,
      onCancelClick,
      onChange,
      onFileChange,
      onDeleteClick,
    },
  } = useOptionCard(type, option);

  const { title, titleEng, sort, active, iconState } = state;

  return (
    <Wrapper>
      <InputWrapper>
        <Row>
          <Label>ID</Label>
          <Input
            value={option?.id || "ADD"}
            disabled
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(e, "id")
            }
          />
        </Row>
        <Row>
          <Label>Icon</Label>
          {option?.icon && <Icon src={option?.icon?.uri} />}
          {editMode && (
            <>
              <Input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                onChange={onFileChange}
              />
              {iconState && <Icon src={URL.createObjectURL(iconState)} />}
            </>
          )}
        </Row>
        <Row>
          <Row>
            <Label>Title</Label>
            <Input
              disabled={!editMode}
              placeholder="옵션 이름"
              value={editMode ? title : option?.title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChange(e, "title")
              }
            />
          </Row>
        </Row>
        <Row>
          <Row>
            <Label>Title in English</Label>
            <Input
              disabled={!editMode}
              placeholder="옵션 영문 이름"
              value={editMode ? titleEng : option?.titleEng}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChange(e, "titleEng")
              }
            />
          </Row>
        </Row>
        <Row>
          <Row>
            <Label>Sort</Label>
            <Input
              type="number"
              disabled={!editMode}
              placeholder="배치 순서"
              value={editMode ? sort : option?.sort}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChange(e, "sort")
              }
            />
          </Row>
          <Row>
            <Label>Active</Label>
            <Input
              type="checkbox"
              disabled={!editMode}
              checked={editMode ? active : option?.active}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChange(e, "active")
              }
            />
          </Row>
        </Row>
      </InputWrapper>

      <ButtonsWrapper>
        <Button onClick={onEditClick}>{option?.id ? "수정" : "추가"}</Button>
        {editMode ? (
          option?.id && (
            <CancelButton onClick={onCancelClick}>취소</CancelButton>
          )
        ) : (
          <DeleteButton onClick={() => onDeleteClick(option!.id)}>
            삭제
          </DeleteButton>
        )}
      </ButtonsWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing.xxSmall2,
}));

const InputWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  marginBottom: theme.spacing.regular,
  width: "100%",
}));

const Row = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: theme.spacing.xxSmall2,
  flex: 1,
  width: "100%",
}));

const Label = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.body3,
  color: theme.color.black5,
}));

const Icon = styled.img<any>(() => ({
  maxWidth: 100,
  maxHeight: 100,
  objectFit: "contain",
}));

const Input = styled.input<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  padding: `${theme.spacing.xxSmall2}px ${theme.spacing.small}px`,
  borderBottom: `1px solid ${theme.color.black6}`,
}));

const ButtonsWrapper = styled(Row)<any>(() => ({
  justifyContent: "flex-end",
}));

const Button = styled.button<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.xxSmall2}px ${theme.spacing.small}px`,
  backgroundColor: theme.color.black2,
  ...theme.font.body3,
  color: theme.color.white,
}));

const CancelButton = styled(Button)<any>(({ theme }: ThemedComponentProps) => ({
  backgroundColor: theme.color.black4,
}));

const DeleteButton = styled(Button)<any>(({ theme }: ThemedComponentProps) => ({
  backgroundColor: theme.color.error,
}));

export default OptionCard;
