import { useGetAllOptionsQuery } from "../../../lib/apollo/graphql/generated";

function useOptionsContainer() {
  const { data, loading, error } = useGetAllOptionsQuery({
    variables: {
      activeOnly: false,
    },
  });

  return {
    models: {
      loading,
      data: data?.getAllOptions,
    },
  };
}

export default useOptionsContainer;
