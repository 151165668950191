import { ReactElement } from "react";
import ContactListContainer from "../../../modules/contact/contactList/container/ContactListContainer";
import MainContainer from "../../../modules/main/container/MainContainer";
import OptionsContainer from "../../../modules/options/container/OptionsContainer";
import PrivacyPolicyContainer from "../../../modules/privacyPolicy/container/PrivacyPolicyContainer";

export type CustomRoute = {
  path: string;
  element: ReactElement;
  name?: string;
  children?: CustomRoute[];
};

export const PATH_NAMES: { [key: string]: CustomRoute } = {
  HOME: {
    path: "/",
    name: "홈",
    element: <MainContainer />,
  },
  OPTIONS: {
    path: "/options",
    name: "문의옵션",
    element: <OptionsContainer />,
  },
  CONTACTS: {
    path: "/contacts",
    name: "문의목록",
    element: <ContactListContainer />,
  },
  PRIVACY_POLICY: {
    path: "/privacy_policy",
    name: "개인정보처리방침",
    element: <PrivacyPolicyContainer />,
  },
};
