import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import OptionCard from "./OptionCard";
import { OptionType } from "../../../lib/apollo/graphql/generated";
import { OPTION_TYPE_NAMES } from "../../../utilities/constants";

interface OptionCategoryCardProps {
  type: OptionType;
  data: ({
    __typename?: "Option" | undefined;
    id: string;
    type: OptionType;
    title: string;
    titleEng: string;
    active: boolean;
    sort: number;
    icon?:
      | { __typename?: "Media" | undefined; id: string; uri: string }
      | null
      | undefined;
  } | null)[];
}

function OptionCategoryCard({ type, data }: OptionCategoryCardProps) {
  return (
    <Wrapper>
      <Category>{OPTION_TYPE_NAMES[type]}</Category>
      <OptionsWrapper>
        {data?.map((option) => (
          <OptionCard key={option?.id} type={type} option={option} />
        ))}
        <OptionCard type={type} option={null} />
      </OptionsWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  flexBasis: "30%",
  borderRadius: 6,
  padding: theme.spacing.small,
  border: `1px solid ${theme.color.black7}`,
}));

const Category = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.head5,
  fontWeight: "bold",
  paddingBottom: theme.spacing.regular,
  borderBottom: `1px solid ${theme.color.black5}`,
  marginBottom: theme.spacing.small,
}));

const OptionsWrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing.small,
}));

export default OptionCategoryCard;
