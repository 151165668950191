import moment from "moment";
import { useState } from "react";
import { useUpdateContactContactedAtAsAdminMutation } from "../../../../lib/apollo/graphql/generated";

function useContactListRow() {
  const [showDetail, setShowDetail] = useState(false);

  const [updateContactContactedAtAsAdmin] =
    useUpdateContactContactedAtAsAdminMutation();

  function toggleShowDetail() {
    setShowDetail(!showDetail);
  }

  function onUpdateContactedAtClick(contactId: string) {
    updateContactContactedAtAsAdmin({
      variables: {
        contactId,
        contactedAt: moment().toISOString(),
      },
    });
  }

  return {
    models: {
      showDetail,
    },
    operations: {
      toggleShowDetail,
      onUpdateContactedAtClick,
    },
  };
}

export default useContactListRow;
