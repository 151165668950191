export const OPTION_TYPES = {
  readinessOptions: "READINESS",
  productOptions: "PRODUCT",
  purposeOptions: "PURPOSE",
  bmOptions: "BM",
  funnelOptions: "FUNNEL",
};

export const OPTION_TYPE_NAMES = {
  READINESS: "준비단계",
  PRODUCT: "상품 선택",
  PURPOSE: "문의 목적",
  BM: "수익 모델",
  FUNNEL: "유입경로",
};
