import moment from "moment";
import React from "react";
import styled, { ThemedComponentProps } from "styled-components";

interface PrivacyPolicyHeaderProps {
  currentId: string;
  versionData?: ({
    __typename?: "PrivacyPolicyVersion" | undefined;
    id: string;
    createdAt: any;
  } | null)[];
  onVersionChange: (
    e?: React.ChangeEvent<HTMLSelectElement>,
    isNew?: boolean
  ) => void;
}

function PrivacyPolicyHeader({
  currentId,
  versionData,
  onVersionChange,
}: PrivacyPolicyHeaderProps) {
  return (
    <Header>
      <div>
        <Select
          value={currentId}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            onVersionChange(e, false)
          }
        >
          {versionData?.map((version) => (
            <option key={version?.id} value={version?.id}>
              {moment(version?.createdAt).format("YYYY-MM-DD")}
            </option>
          ))}
        </Select>
      </div>
      <button onClick={() => onVersionChange(undefined, true)}>
        Register new
      </button>
    </Header>
  );
}

const Header = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: `${theme.spacing.default}px ${theme.spacing.normal}px`,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: theme.color.black8,
  ...theme.font.body2,
}));

const Select = styled.select<any>(({ theme }: ThemedComponentProps) => ({
  fontSize: "inherit",
  padding: `${theme.spacing.small}px ${theme.spacing.normal}px`,
}));

export default PrivacyPolicyHeader;
