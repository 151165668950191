import React from "react";
import styled, { ThemedComponentProps } from "styled-components";
import useContactListContainer from "./ContactListContainer.hook";
import ContactListHeader from "../components/ContactListHeader";
import ContactListRow from "../components/ContactListRow";

function ContactListContainer() {
  const {
    refs: { fetchMoreRef },
    models: {
      searchedQuery,
      isSearched,
      loading,
      data,
      searchData,
      fetchingMore,
    },
    operations: { onSearch, onRefetchClick },
  } = useContactListContainer();

  if (loading) return <div />;

  const showingData = isSearched ? searchData : data;

  return (
    <Wrapper>
      <ContactListHeader
        searchedQuery={searchedQuery}
        onSearch={onSearch}
        onRefetchClick={onRefetchClick}
      />
      <Table>
        <THead>
          <tr>
            <Th>ID</Th>
            <Th>단체명</Th>
            <Th>담당자</Th>
            <Th>전화번호</Th>
            <Th>이메일</Th>
            <Th>예산</Th>
            <Th>상품</Th>
            <Th>파일</Th>
            <Th>문의일자</Th>
            <Th>연락여부</Th>
            <Th>더보기</Th>
          </tr>
        </THead>
        <TBody>
          {showingData?.map((contact) => (
            <ContactListRow key={contact?.id} contact={contact} />
          ))}

          <tr style={{ visibility: "hidden" }}>
            <td ref={fetchMoreRef} colSpan={10}>
              Fetch More
            </td>
          </tr>
        </TBody>
      </Table>
    </Wrapper>
  );
}

const Wrapper = styled.div<any>(({ theme }: ThemedComponentProps) => ({
  padding: theme.spacing.middle,
}));

const Table = styled.table<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  height: "100%",
  borderSpacing: 0,
}));

const THead = styled.thead<any>(({ theme }: ThemedComponentProps) => ({
  backgroundColor: theme.color.background,
}));

const Th = styled.th<any>(({ theme }: ThemedComponentProps) => ({
  ...theme.font.body1,
  textAlign: "left",
  padding: `${theme.spacing.xxSmall2}px ${theme.spacing.small}px`,
  border: `1px solid ${theme.color.black6}`,
}));

const TBody = styled.tbody<any>(({ theme }: ThemedComponentProps) => ({
  width: "100%",
  height: "100%",
  overflowY: "auto",
  ...theme.font.body2,
}));

export default ContactListContainer;
