import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  PhoneNumber: any;
  Upload: any;
};

export type AllOption = {
  __typename?: 'AllOption';
  bmOptions: Array<Maybe<Option>>;
  funnelOptions: Array<Maybe<Option>>;
  productOptions: Array<Maybe<Option>>;
  purposeOptions: Array<Maybe<Option>>;
  readinessOptions: Array<Maybe<Option>>;
};

export type Contact = {
  __typename?: 'Contact';
  bmOptions?: Maybe<Array<Maybe<Option>>>;
  budget: Scalars['String'];
  businessField: Scalars['String'];
  contactedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  funnelOptions?: Maybe<Array<Maybe<Option>>>;
  id: Scalars['ID'];
  managerName: Scalars['String'];
  managerPosition: Scalars['String'];
  media?: Maybe<Media>;
  phone: Scalars['String'];
  productOptions?: Maybe<Array<Maybe<Option>>>;
  purposeOptions?: Maybe<Array<Maybe<Option>>>;
  readinessOptions?: Maybe<Array<Maybe<Option>>>;
  team: Scalars['String'];
};

export type ContactConnection = {
  __typename?: 'ContactConnection';
  edges: Array<Maybe<Contact>>;
  pageInfo: PageInfo;
};

export type ContactInput = {
  bmOptionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  budget: Scalars['String'];
  businessField: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  funnelOptionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  managerName: Scalars['String'];
  managerPosition: Scalars['String'];
  mediaInput?: InputMaybe<MediaInput>;
  phone: Scalars['String'];
  productOptionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  purposeOptionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  readinessOptionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  team: Scalars['String'];
};

export type File = {
  __typename?: 'File';
  encoding: Scalars['String'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
};

export type Media = {
  __typename?: 'Media';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int'];
  /** This is provided only when thumbnailFile is uploaded */
  thumbnailUri?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  uri: Scalars['String'];
};

export type MediaInput = {
  file: Scalars['Upload'];
  thumbnailFile?: InputMaybe<Scalars['Upload']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['Boolean']>;
  createContact: Contact;
  deleteOptionAsAdmin: Scalars['Boolean'];
  updateContactContactedAtAsAdmin: Contact;
  updateOptionAsAdmin: Option;
  updatePrivacyPolicyAsAdmin: PrivacyPolicy;
};


export type MutationCreateContactArgs = {
  contactInput: ContactInput;
};


export type MutationDeleteOptionAsAdminArgs = {
  optionId: Scalars['ID'];
  type: OptionType;
};


export type MutationUpdateContactContactedAtAsAdminArgs = {
  contactId: Scalars['ID'];
  contactedAt: Scalars['DateTime'];
};


export type MutationUpdateOptionAsAdminArgs = {
  optionInput: OptionInput;
};


export type MutationUpdatePrivacyPolicyAsAdminArgs = {
  privacyPolicyInput: PrivacyPolicyInput;
};

export type Option = {
  __typename?: 'Option';
  active: Scalars['Boolean'];
  icon?: Maybe<Media>;
  id: Scalars['ID'];
  sort: Scalars['Int'];
  title: Scalars['String'];
  titleEng: Scalars['String'];
  type: OptionType;
};

export type OptionInput = {
  active: Scalars['Boolean'];
  iconInput?: InputMaybe<MediaInput>;
  id?: InputMaybe<Scalars['ID']>;
  sort: Scalars['Int'];
  title: Scalars['String'];
  titleEng: Scalars['String'];
  type: OptionType;
};

export enum OptionType {
  Bm = 'BM',
  Funnel = 'FUNNEL',
  Product = 'PRODUCT',
  Purpose = 'PURPOSE',
  Readiness = 'READINESS'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
};

export type PaginationInput = {
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
};

export type PrivacyPolicy = {
  __typename?: 'PrivacyPolicy';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  policy: Scalars['String'];
};

export type PrivacyPolicyInput = {
  id?: InputMaybe<Scalars['ID']>;
  policy: Scalars['String'];
};

export type PrivacyPolicyVersion = {
  __typename?: 'PrivacyPolicyVersion';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['Boolean']>;
  getAllOptions: AllOption;
  getContactAsAdmin: Contact;
  getContactsAsAdmin: ContactConnection;
  getLatestPrivacyPolicy: PrivacyPolicy;
  getOptionTypes: Array<Maybe<OptionType>>;
  getPrivacyPolicy: PrivacyPolicy;
  getPrivacyPolicyVersions: Array<Maybe<PrivacyPolicyVersion>>;
  searchContactsAsAdmin: ContactConnection;
};


export type QueryGetAllOptionsArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetContactAsAdminArgs = {
  contactId: Scalars['ID'];
};


export type QueryGetContactsAsAdminArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryGetPrivacyPolicyArgs = {
  policyId: Scalars['ID'];
};


export type QuerySearchContactsAsAdminArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};

export type GetContactsAsAdminQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type GetContactsAsAdminQuery = { __typename?: 'Query', getContactsAsAdmin: { __typename?: 'ContactConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Contact', id: string, team: string, businessField: string, managerName: string, managerPosition: string, phone: string, email: string, budget: string, description?: string | null, contactedAt?: any | null, createdAt: any, media?: { __typename?: 'Media', id: string, uri: string, type: string } | null, readinessOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null, productOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null, purposeOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null, bmOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null, funnelOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null } | null> } };

export type GetContactAsAdminQueryVariables = Exact<{
  contactId: Scalars['ID'];
}>;


export type GetContactAsAdminQuery = { __typename?: 'Query', getContactAsAdmin: { __typename?: 'Contact', id: string, team: string, businessField: string, managerName: string, managerPosition: string, phone: string, email: string, budget: string, description?: string | null, contactedAt?: any | null, createdAt: any, media?: { __typename?: 'Media', id: string, uri: string, type: string } | null, readinessOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null, productOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null, purposeOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null, bmOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null, funnelOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null } };

export type SearchContactsAsAdminQueryVariables = Exact<{
  query: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type SearchContactsAsAdminQuery = { __typename?: 'Query', searchContactsAsAdmin: { __typename?: 'ContactConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'Contact', id: string, team: string, businessField: string, managerName: string, managerPosition: string, phone: string, email: string, budget: string, description?: string | null, contactedAt?: any | null, createdAt: any, media?: { __typename?: 'Media', id: string, uri: string, type: string } | null, readinessOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null, productOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null, purposeOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null, bmOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null, funnelOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null } | null> } };

export type UpdateContactContactedAtAsAdminMutationVariables = Exact<{
  contactId: Scalars['ID'];
  contactedAt: Scalars['DateTime'];
}>;


export type UpdateContactContactedAtAsAdminMutation = { __typename?: 'Mutation', updateContactContactedAtAsAdmin: { __typename?: 'Contact', id: string, team: string, businessField: string, managerName: string, managerPosition: string, phone: string, email: string, budget: string, description?: string | null, contactedAt?: any | null, createdAt: any, media?: { __typename?: 'Media', id: string, uri: string, type: string } | null, readinessOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null, productOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null, purposeOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null, bmOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null, funnelOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null } };

export type OptionFieldFragment = { __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null };

export type PageInfoFieldFragment = { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null };

export type ContactFieldFragment = { __typename?: 'Contact', id: string, team: string, businessField: string, managerName: string, managerPosition: string, phone: string, email: string, budget: string, description?: string | null, contactedAt?: any | null, createdAt: any, media?: { __typename?: 'Media', id: string, uri: string, type: string } | null, readinessOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null, productOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null, purposeOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null, bmOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null, funnelOptions?: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> | null };

export type PrivacyPolicyFieldFragment = { __typename?: 'PrivacyPolicy', id: string, policy: string, createdAt: any };

export type GetOptionTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOptionTypesQuery = { __typename?: 'Query', getOptionTypes: Array<OptionType | null> };

export type GetAllOptionsQueryVariables = Exact<{
  activeOnly?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetAllOptionsQuery = { __typename?: 'Query', getAllOptions: { __typename?: 'AllOption', readinessOptions: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null>, productOptions: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null>, purposeOptions: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null>, bmOptions: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null>, funnelOptions: Array<{ __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } | null> } };

export type UpdateOptionAsAdminMutationVariables = Exact<{
  optionInput: OptionInput;
}>;


export type UpdateOptionAsAdminMutation = { __typename?: 'Mutation', updateOptionAsAdmin: { __typename?: 'Option', id: string, type: OptionType, title: string, titleEng: string, active: boolean, sort: number, icon?: { __typename?: 'Media', id: string, uri: string } | null } };

export type DeleteOptionAsAdminMutationVariables = Exact<{
  optionId: Scalars['ID'];
  type: OptionType;
}>;


export type DeleteOptionAsAdminMutation = { __typename?: 'Mutation', deleteOptionAsAdmin: boolean };

export type GetPrivacyPolicyVersionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPrivacyPolicyVersionsQuery = { __typename?: 'Query', getPrivacyPolicyVersions: Array<{ __typename?: 'PrivacyPolicyVersion', id: string, createdAt: any } | null> };

export type GetPrivacyPolicyQueryVariables = Exact<{
  policyId: Scalars['ID'];
}>;


export type GetPrivacyPolicyQuery = { __typename?: 'Query', getPrivacyPolicy: { __typename?: 'PrivacyPolicy', id: string, policy: string, createdAt: any } };

export type UpdatePrivacyPolicyAsAdminMutationVariables = Exact<{
  privacyPolicyInput: PrivacyPolicyInput;
}>;


export type UpdatePrivacyPolicyAsAdminMutation = { __typename?: 'Mutation', updatePrivacyPolicyAsAdmin: { __typename?: 'PrivacyPolicy', id: string, policy: string, createdAt: any } };

export const PageInfoFieldFragmentDoc = gql`
    fragment PageInfoField on PageInfo {
  hasNextPage
  endCursor
}
    `;
export const OptionFieldFragmentDoc = gql`
    fragment OptionField on Option {
  id
  type
  title
  titleEng
  active
  sort
  icon {
    id
    uri
  }
}
    `;
export const ContactFieldFragmentDoc = gql`
    fragment ContactField on Contact {
  id
  team
  businessField
  managerName
  managerPosition
  phone
  email
  budget
  description
  media {
    id
    uri
    type
  }
  readinessOptions {
    ...OptionField
  }
  productOptions {
    ...OptionField
  }
  purposeOptions {
    ...OptionField
  }
  bmOptions {
    ...OptionField
  }
  funnelOptions {
    ...OptionField
  }
  contactedAt
  createdAt
}
    ${OptionFieldFragmentDoc}`;
export const PrivacyPolicyFieldFragmentDoc = gql`
    fragment PrivacyPolicyField on PrivacyPolicy {
  id
  policy
  createdAt
}
    `;
export const GetContactsAsAdminDocument = gql`
    query GetContactsAsAdmin($first: Int, $after: String) {
  getContactsAsAdmin(first: $first, after: $after) {
    pageInfo {
      ...PageInfoField
    }
    edges {
      ...ContactField
    }
  }
}
    ${PageInfoFieldFragmentDoc}
${ContactFieldFragmentDoc}`;

/**
 * __useGetContactsAsAdminQuery__
 *
 * To run a query within a React component, call `useGetContactsAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsAsAdminQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetContactsAsAdminQuery(baseOptions?: Apollo.QueryHookOptions<GetContactsAsAdminQuery, GetContactsAsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactsAsAdminQuery, GetContactsAsAdminQueryVariables>(GetContactsAsAdminDocument, options);
      }
export function useGetContactsAsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactsAsAdminQuery, GetContactsAsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactsAsAdminQuery, GetContactsAsAdminQueryVariables>(GetContactsAsAdminDocument, options);
        }
export type GetContactsAsAdminQueryHookResult = ReturnType<typeof useGetContactsAsAdminQuery>;
export type GetContactsAsAdminLazyQueryHookResult = ReturnType<typeof useGetContactsAsAdminLazyQuery>;
export type GetContactsAsAdminQueryResult = Apollo.QueryResult<GetContactsAsAdminQuery, GetContactsAsAdminQueryVariables>;
export const GetContactAsAdminDocument = gql`
    query GetContactAsAdmin($contactId: ID!) {
  getContactAsAdmin(contactId: $contactId) {
    ...ContactField
  }
}
    ${ContactFieldFragmentDoc}`;

/**
 * __useGetContactAsAdminQuery__
 *
 * To run a query within a React component, call `useGetContactAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactAsAdminQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useGetContactAsAdminQuery(baseOptions: Apollo.QueryHookOptions<GetContactAsAdminQuery, GetContactAsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactAsAdminQuery, GetContactAsAdminQueryVariables>(GetContactAsAdminDocument, options);
      }
export function useGetContactAsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactAsAdminQuery, GetContactAsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactAsAdminQuery, GetContactAsAdminQueryVariables>(GetContactAsAdminDocument, options);
        }
export type GetContactAsAdminQueryHookResult = ReturnType<typeof useGetContactAsAdminQuery>;
export type GetContactAsAdminLazyQueryHookResult = ReturnType<typeof useGetContactAsAdminLazyQuery>;
export type GetContactAsAdminQueryResult = Apollo.QueryResult<GetContactAsAdminQuery, GetContactAsAdminQueryVariables>;
export const SearchContactsAsAdminDocument = gql`
    query SearchContactsAsAdmin($query: String!, $first: Int, $after: String) {
  searchContactsAsAdmin(query: $query, first: $first, after: $after) {
    pageInfo {
      ...PageInfoField
    }
    edges {
      ...ContactField
    }
  }
}
    ${PageInfoFieldFragmentDoc}
${ContactFieldFragmentDoc}`;

/**
 * __useSearchContactsAsAdminQuery__
 *
 * To run a query within a React component, call `useSearchContactsAsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchContactsAsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchContactsAsAdminQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSearchContactsAsAdminQuery(baseOptions: Apollo.QueryHookOptions<SearchContactsAsAdminQuery, SearchContactsAsAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchContactsAsAdminQuery, SearchContactsAsAdminQueryVariables>(SearchContactsAsAdminDocument, options);
      }
export function useSearchContactsAsAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchContactsAsAdminQuery, SearchContactsAsAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchContactsAsAdminQuery, SearchContactsAsAdminQueryVariables>(SearchContactsAsAdminDocument, options);
        }
export type SearchContactsAsAdminQueryHookResult = ReturnType<typeof useSearchContactsAsAdminQuery>;
export type SearchContactsAsAdminLazyQueryHookResult = ReturnType<typeof useSearchContactsAsAdminLazyQuery>;
export type SearchContactsAsAdminQueryResult = Apollo.QueryResult<SearchContactsAsAdminQuery, SearchContactsAsAdminQueryVariables>;
export const UpdateContactContactedAtAsAdminDocument = gql`
    mutation UpdateContactContactedAtAsAdmin($contactId: ID!, $contactedAt: DateTime!) {
  updateContactContactedAtAsAdmin(
    contactId: $contactId
    contactedAt: $contactedAt
  ) {
    ...ContactField
  }
}
    ${ContactFieldFragmentDoc}`;
export type UpdateContactContactedAtAsAdminMutationFn = Apollo.MutationFunction<UpdateContactContactedAtAsAdminMutation, UpdateContactContactedAtAsAdminMutationVariables>;

/**
 * __useUpdateContactContactedAtAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateContactContactedAtAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactContactedAtAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactContactedAtAsAdminMutation, { data, loading, error }] = useUpdateContactContactedAtAsAdminMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      contactedAt: // value for 'contactedAt'
 *   },
 * });
 */
export function useUpdateContactContactedAtAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactContactedAtAsAdminMutation, UpdateContactContactedAtAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactContactedAtAsAdminMutation, UpdateContactContactedAtAsAdminMutationVariables>(UpdateContactContactedAtAsAdminDocument, options);
      }
export type UpdateContactContactedAtAsAdminMutationHookResult = ReturnType<typeof useUpdateContactContactedAtAsAdminMutation>;
export type UpdateContactContactedAtAsAdminMutationResult = Apollo.MutationResult<UpdateContactContactedAtAsAdminMutation>;
export type UpdateContactContactedAtAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateContactContactedAtAsAdminMutation, UpdateContactContactedAtAsAdminMutationVariables>;
export const GetOptionTypesDocument = gql`
    query GetOptionTypes {
  getOptionTypes
}
    `;

/**
 * __useGetOptionTypesQuery__
 *
 * To run a query within a React component, call `useGetOptionTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOptionTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOptionTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOptionTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetOptionTypesQuery, GetOptionTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOptionTypesQuery, GetOptionTypesQueryVariables>(GetOptionTypesDocument, options);
      }
export function useGetOptionTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOptionTypesQuery, GetOptionTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOptionTypesQuery, GetOptionTypesQueryVariables>(GetOptionTypesDocument, options);
        }
export type GetOptionTypesQueryHookResult = ReturnType<typeof useGetOptionTypesQuery>;
export type GetOptionTypesLazyQueryHookResult = ReturnType<typeof useGetOptionTypesLazyQuery>;
export type GetOptionTypesQueryResult = Apollo.QueryResult<GetOptionTypesQuery, GetOptionTypesQueryVariables>;
export const GetAllOptionsDocument = gql`
    query GetAllOptions($activeOnly: Boolean) {
  getAllOptions(activeOnly: $activeOnly) {
    readinessOptions {
      ...OptionField
    }
    productOptions {
      ...OptionField
    }
    purposeOptions {
      ...OptionField
    }
    bmOptions {
      ...OptionField
    }
    funnelOptions {
      ...OptionField
    }
  }
}
    ${OptionFieldFragmentDoc}`;

/**
 * __useGetAllOptionsQuery__
 *
 * To run a query within a React component, call `useGetAllOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOptionsQuery({
 *   variables: {
 *      activeOnly: // value for 'activeOnly'
 *   },
 * });
 */
export function useGetAllOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllOptionsQuery, GetAllOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllOptionsQuery, GetAllOptionsQueryVariables>(GetAllOptionsDocument, options);
      }
export function useGetAllOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllOptionsQuery, GetAllOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllOptionsQuery, GetAllOptionsQueryVariables>(GetAllOptionsDocument, options);
        }
export type GetAllOptionsQueryHookResult = ReturnType<typeof useGetAllOptionsQuery>;
export type GetAllOptionsLazyQueryHookResult = ReturnType<typeof useGetAllOptionsLazyQuery>;
export type GetAllOptionsQueryResult = Apollo.QueryResult<GetAllOptionsQuery, GetAllOptionsQueryVariables>;
export const UpdateOptionAsAdminDocument = gql`
    mutation UpdateOptionAsAdmin($optionInput: OptionInput!) {
  updateOptionAsAdmin(optionInput: $optionInput) {
    ...OptionField
  }
}
    ${OptionFieldFragmentDoc}`;
export type UpdateOptionAsAdminMutationFn = Apollo.MutationFunction<UpdateOptionAsAdminMutation, UpdateOptionAsAdminMutationVariables>;

/**
 * __useUpdateOptionAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdateOptionAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOptionAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOptionAsAdminMutation, { data, loading, error }] = useUpdateOptionAsAdminMutation({
 *   variables: {
 *      optionInput: // value for 'optionInput'
 *   },
 * });
 */
export function useUpdateOptionAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOptionAsAdminMutation, UpdateOptionAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOptionAsAdminMutation, UpdateOptionAsAdminMutationVariables>(UpdateOptionAsAdminDocument, options);
      }
export type UpdateOptionAsAdminMutationHookResult = ReturnType<typeof useUpdateOptionAsAdminMutation>;
export type UpdateOptionAsAdminMutationResult = Apollo.MutationResult<UpdateOptionAsAdminMutation>;
export type UpdateOptionAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdateOptionAsAdminMutation, UpdateOptionAsAdminMutationVariables>;
export const DeleteOptionAsAdminDocument = gql`
    mutation DeleteOptionAsAdmin($optionId: ID!, $type: OptionType!) {
  deleteOptionAsAdmin(optionId: $optionId, type: $type)
}
    `;
export type DeleteOptionAsAdminMutationFn = Apollo.MutationFunction<DeleteOptionAsAdminMutation, DeleteOptionAsAdminMutationVariables>;

/**
 * __useDeleteOptionAsAdminMutation__
 *
 * To run a mutation, you first call `useDeleteOptionAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOptionAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOptionAsAdminMutation, { data, loading, error }] = useDeleteOptionAsAdminMutation({
 *   variables: {
 *      optionId: // value for 'optionId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useDeleteOptionAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOptionAsAdminMutation, DeleteOptionAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOptionAsAdminMutation, DeleteOptionAsAdminMutationVariables>(DeleteOptionAsAdminDocument, options);
      }
export type DeleteOptionAsAdminMutationHookResult = ReturnType<typeof useDeleteOptionAsAdminMutation>;
export type DeleteOptionAsAdminMutationResult = Apollo.MutationResult<DeleteOptionAsAdminMutation>;
export type DeleteOptionAsAdminMutationOptions = Apollo.BaseMutationOptions<DeleteOptionAsAdminMutation, DeleteOptionAsAdminMutationVariables>;
export const GetPrivacyPolicyVersionsDocument = gql`
    query GetPrivacyPolicyVersions {
  getPrivacyPolicyVersions {
    id
    createdAt
  }
}
    `;

/**
 * __useGetPrivacyPolicyVersionsQuery__
 *
 * To run a query within a React component, call `useGetPrivacyPolicyVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivacyPolicyVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivacyPolicyVersionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPrivacyPolicyVersionsQuery(baseOptions?: Apollo.QueryHookOptions<GetPrivacyPolicyVersionsQuery, GetPrivacyPolicyVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrivacyPolicyVersionsQuery, GetPrivacyPolicyVersionsQueryVariables>(GetPrivacyPolicyVersionsDocument, options);
      }
export function useGetPrivacyPolicyVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrivacyPolicyVersionsQuery, GetPrivacyPolicyVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrivacyPolicyVersionsQuery, GetPrivacyPolicyVersionsQueryVariables>(GetPrivacyPolicyVersionsDocument, options);
        }
export type GetPrivacyPolicyVersionsQueryHookResult = ReturnType<typeof useGetPrivacyPolicyVersionsQuery>;
export type GetPrivacyPolicyVersionsLazyQueryHookResult = ReturnType<typeof useGetPrivacyPolicyVersionsLazyQuery>;
export type GetPrivacyPolicyVersionsQueryResult = Apollo.QueryResult<GetPrivacyPolicyVersionsQuery, GetPrivacyPolicyVersionsQueryVariables>;
export const GetPrivacyPolicyDocument = gql`
    query GetPrivacyPolicy($policyId: ID!) {
  getPrivacyPolicy(policyId: $policyId) {
    ...PrivacyPolicyField
  }
}
    ${PrivacyPolicyFieldFragmentDoc}`;

/**
 * __useGetPrivacyPolicyQuery__
 *
 * To run a query within a React component, call `useGetPrivacyPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivacyPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivacyPolicyQuery({
 *   variables: {
 *      policyId: // value for 'policyId'
 *   },
 * });
 */
export function useGetPrivacyPolicyQuery(baseOptions: Apollo.QueryHookOptions<GetPrivacyPolicyQuery, GetPrivacyPolicyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrivacyPolicyQuery, GetPrivacyPolicyQueryVariables>(GetPrivacyPolicyDocument, options);
      }
export function useGetPrivacyPolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrivacyPolicyQuery, GetPrivacyPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrivacyPolicyQuery, GetPrivacyPolicyQueryVariables>(GetPrivacyPolicyDocument, options);
        }
export type GetPrivacyPolicyQueryHookResult = ReturnType<typeof useGetPrivacyPolicyQuery>;
export type GetPrivacyPolicyLazyQueryHookResult = ReturnType<typeof useGetPrivacyPolicyLazyQuery>;
export type GetPrivacyPolicyQueryResult = Apollo.QueryResult<GetPrivacyPolicyQuery, GetPrivacyPolicyQueryVariables>;
export const UpdatePrivacyPolicyAsAdminDocument = gql`
    mutation UpdatePrivacyPolicyAsAdmin($privacyPolicyInput: PrivacyPolicyInput!) {
  updatePrivacyPolicyAsAdmin(privacyPolicyInput: $privacyPolicyInput) {
    id
    policy
    createdAt
  }
}
    `;
export type UpdatePrivacyPolicyAsAdminMutationFn = Apollo.MutationFunction<UpdatePrivacyPolicyAsAdminMutation, UpdatePrivacyPolicyAsAdminMutationVariables>;

/**
 * __useUpdatePrivacyPolicyAsAdminMutation__
 *
 * To run a mutation, you first call `useUpdatePrivacyPolicyAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrivacyPolicyAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrivacyPolicyAsAdminMutation, { data, loading, error }] = useUpdatePrivacyPolicyAsAdminMutation({
 *   variables: {
 *      privacyPolicyInput: // value for 'privacyPolicyInput'
 *   },
 * });
 */
export function useUpdatePrivacyPolicyAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePrivacyPolicyAsAdminMutation, UpdatePrivacyPolicyAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePrivacyPolicyAsAdminMutation, UpdatePrivacyPolicyAsAdminMutationVariables>(UpdatePrivacyPolicyAsAdminDocument, options);
      }
export type UpdatePrivacyPolicyAsAdminMutationHookResult = ReturnType<typeof useUpdatePrivacyPolicyAsAdminMutation>;
export type UpdatePrivacyPolicyAsAdminMutationResult = Apollo.MutationResult<UpdatePrivacyPolicyAsAdminMutation>;
export type UpdatePrivacyPolicyAsAdminMutationOptions = Apollo.BaseMutationOptions<UpdatePrivacyPolicyAsAdminMutation, UpdatePrivacyPolicyAsAdminMutationVariables>;