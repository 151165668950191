import { useState } from "react";
import { useInView } from "react-intersection-observer";
import {
  useGetContactsAsAdminQuery,
  useSearchContactsAsAdminLazyQuery,
} from "../../../../lib/apollo/graphql/generated";

function useContactListContainer() {
  const { ref: fetchMoreRef, inView } = useInView({
    onChange: onFetchMore,
  });

  const [isSearched, setIsSearched] = useState(false);
  const [searchedQuery, setSearchedQuery] = useState("");

  const [fetchingMore, setFetchingMore] = useState(false);

  const { loading, data, refetch, fetchMore } = useGetContactsAsAdminQuery({
    variables: {
      first: 10,
    },
  });

  const [
    searchContactsAsAdmin,
    {
      loading: searchLoading,
      data: searchData,
      refetch: searchRefetch,
      fetchMore: searchFetchMore,
    },
  ] = useSearchContactsAsAdminLazyQuery({
    onCompleted: () => {
      setIsSearched(true);
    },
  });

  function onSearch(query: string) {
    if (query) {
      setSearchedQuery(query);
      searchContactsAsAdmin({
        variables: {
          query,
          first: 10,
        },
      });
    } else {
      setSearchedQuery("");
      setIsSearched(false);
    }
  }

  function onRefetchClick() {
    if (isSearched) {
      searchRefetch();
    } else {
      refetch();
    }
  }

  async function onFetchMore(inView: boolean) {
    if (inView && !fetchingMore) {
      const { hasNextPage, endCursor } = isSearched
        ? searchData?.searchContactsAsAdmin.pageInfo!
        : data?.getContactsAsAdmin.pageInfo!;

      if (hasNextPage) {
        if (!isSearched) {
          await fetchMore({
            variables: {
              first: 10,
              after: endCursor,
            },
          });
        } else {
          await searchFetchMore({
            variables: {
              query: searchedQuery,
              first: 10,
              after: endCursor,
            },
          });
        }

        setFetchingMore(false);
      }
    }
  }

  return {
    refs: {
      fetchMoreRef,
    },
    models: {
      searchedQuery,
      loading: loading || searchLoading,
      isSearched,
      data: data?.getContactsAsAdmin?.edges,
      searchData: searchData?.searchContactsAsAdmin?.edges,
      fetchingMore,
    },

    operations: {
      onSearch,
      onRefetchClick,
    },
  };
}

export default useContactListContainer;
