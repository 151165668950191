import { ApolloProvider } from "@apollo/client";
import React from "react";
import { ThemeProvider } from "styled-components";
import client from "./lib/apollo";
import RouterContainer from "./lib/router/container/RouterContainer";
import FirebaseProvider from "./modules/common/providers/FirebaseProvider";
import { GlobalStyle, theme } from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ApolloProvider client={client}>
        <FirebaseProvider>
          <RouterContainer />
        </FirebaseProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
