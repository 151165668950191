import { useEffect, useState } from "react";

function useContactListHeader(searchedQuery: string) {
  const [query, setQuery] = useState("");

  useEffect(() => {
    setQuery(searchedQuery);
  }, [searchedQuery]);

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;

    setQuery(value);
  }

  return {
    models: {
      query,
    },
    operations: {
      onChange,
    },
  };
}

export default useContactListHeader;
